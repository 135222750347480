import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "46",
  height: "42",
  viewBox: "0 0 46 42",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#212529",
      d: "M3.30118 0.445374V41.4948H42.6866V0.445374H3.30118ZM15.6196 31.4988H9.26287V25.1421H15.6196V31.4988ZM15.6196 21.6824H9.26287V15.3257H15.6196V21.6824ZM15.6196 12.0096H9.26287V5.65286H15.6196V12.0096ZM26.1783 31.4988H19.8215V25.1421H26.1783V31.4988ZM26.1783 21.6824H19.8215V15.3257H26.1783V21.6824ZM26.1783 12.0096H19.8215V5.65286H26.1783V12.0096ZM36.7369 31.4988H30.3802V25.1421H36.7369V31.4988ZM36.7369 21.6824H30.3802V15.3257H36.7369V21.6824ZM36.7369 12.0096H30.3802V5.65286H36.7369V12.0096Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#F06F02",
      d: "M45.5 37.7598H0.5V41.5546H45.5V37.7598Z"
    }, null, -1)
  ])))
}
export default { render: render }